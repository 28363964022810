import React, { useState } from 'react'
import { useSelector } from 'react-redux'

export default function Orderbook() {
    const asks = useSelector(state => state.orderbook.asks)
    const bids = useSelector(state => state.orderbook.bids)

    return (
        <div>
            <h2>Orderbook</h2>

            <h4>Asks</h4>
            {
                [...asks].reverse().map(x => {
                    return <div key={x.price}>{x.price} {x.size}</div>
                })            
            }

            <h4>Bids</h4>
            {
                [...bids].reverse().map(x => {
                    return <div key={x.price}>{x.price} {x.size}</div>
                })            
            }
        </div>
    )
}
