import React from 'react';
import { useSelector } from 'react-redux';

import Moment from 'moment'



export default function TradeHistory() {
    const trades = useSelector(state => state.trades.trades)

    return (
        <div>
            Trade History ({trades.length})

            { [...trades].reverse().map(trade => {
                return <div key={trade.timestamp}>{trade.size} {trade.price} {Moment.unix(trade.timestamp / 1e6).format('HH:mm:SS')}</div>
            })}
        </div>
    )
}