import axios from 'axios'
import React, { useState } from 'react'

import { Container, Form, FormControl, InputGroup, Button, ButtonGroup } from 'react-bootstrap'

const API_REST_BASE_URL = "/api"
const API_WS_BASE_URL = "ws://localhost:1234"

export default function OrderForm() {
    const [ market, setMarket ] = useState('BTC_USD')
    const [ side, setSide ] = useState("BUY")
    const [ amount, setAmount ] = useState(undefined)
    const [ price, setPrice ] = useState(undefined)

    const handlePlaceOrderBtn = () => {
        const order = {
            side,
            amount,
            price,
            market,
        }
        console.log('Placing order:', order)
        axios.post(`${API_REST_BASE_URL}/order`, order)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.error(`Error sending trade order: ${err}`)
            })
    }

    const getAmountSymbol = () => {
        if (side === 'BUY') {
            return market.split('_')[1]
        } else {
            return market.split('_')[0]
        }        
    }

    const getPriceSymbol = () => {
        if (side === 'BUY') {
            return market.split('_')[0]
        } else {
            return market.split('_')[1]
        }        
    }

    const handleChangeSide = (e, side) => {
        e.preventDefault()
        setSide(side)
    }

    const buySelectionClass = () => {
        console.log('buySelectionClass')
        if (side === "BUY") {
            return "active"
        } else {
            return ""
        }
    }

    const sellSelectionClass = () => {
        if (side === "SELL") {
            return "active"
        } else {
            return ""
        }
    }

    return (
        <Form>
            <ButtonGroup>
                <Button variant="secondary" className={() => buySelectionClass()} onClick={(e) => handleChangeSide(e, 'BUY')}>BUY</Button>
                <Button variant="secondary" className={() => sellSelectionClass()} onClick={(e) => handleChangeSide(e, 'SELL')}>SELL</Button>
            </ButtonGroup>

            <Form.Group>
                <Form.Label>Price</Form.Label>
                <InputGroup>
                    <FormControl id="price" onChange={e => setPrice(e.target.value)} />
                    <InputGroup.Text>{ getPriceSymbol() }</InputGroup.Text>
                </InputGroup>             
            </Form.Group>

            <Form.Group>
                <Form.Label>Amount</Form.Label>
                <InputGroup>
                    <FormControl id="amount" onChange={e => setAmount(e.target.value)} />
                    <InputGroup.Text>{ getAmountSymbol() }</InputGroup.Text>
                </InputGroup>
            </Form.Group>

            <Button onClick={() => handlePlaceOrderBtn()}>PLACE { side } ORDER</Button>
        </Form>
    )
}