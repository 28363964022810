import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Trade from './screens/Trade'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Trade} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
