import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    trades: []
}

const tradeSlice = createSlice({
    name: 'trades',
    initialState,
    reducers: {
        newTrade: (state, { payload }) => {
            state.trades.push(payload)
            state.trades = state.trades.slice(-100)   // keep only most recent 100 trades
        }
    }
})

const { reducer, actions } = tradeSlice

export const { newTrade } = actions
export default reducer