import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Container, Row, Col, Form, FormControl, InputGroup, Button, ButtonGroup } from 'react-bootstrap'


import TradeForm from '../../components/OrderForm'
import TradeHistory from '../../components/TradeHistory'
import Orderbook from '../../components/Orderbook'

import { l2Update, snapshot } from '../../slices/orderbookSlice'
import { newTrade } from '../../slices/tradesSlice'

const API_BASE_WS_URL = "ws://localhost:4000"


export default function Trade() {
    const [ market, setMarket ] = useState('BTC_USD')
    const [ side, setSide ] = useState("BUY")

    const dispatch = useDispatch()
    const ws = useRef(null)

    useEffect(() => {
        ws.current = new WebSocket(`${API_BASE_WS_URL}/api/feed/${market}`)
        ws.current.onopen = () => console.log('ws opened')
        ws.current.onclose = () => console.log('ws closed')
        ws.current.onmessage = e => {
            const data = JSON.parse(e.data)
            if (data.type === 'trade') {
                dispatch(newTrade(data))
            } else if (data.type === 'snapshot') {
                dispatch(snapshot(data))
            } else if (data.type === 'update') {
                dispatch(l2Update(data))
            }
        }

        return () => {
            ws.current.close();
        }
    }, [market])

    const getAmountSymbol = () => {
        if (side === 'BUY') {
            return market.split('_')[1]
        } else {
            return market.split('_')[0]
        }        
    }

    const getPriceSymbol = () => {
        if (side === 'BUY') {
            return market.split('_')[0]
        } else {
            return market.split('_')[1]
        }        
    }

    const handleChangeSide = (e, side) => {
        e.preventDefault()
        setSide(side)
    }

    const buySelectionClass = () => {
        console.log('buySelectionClass')
        if (side === "BUY") {
            return "active"
        } else {
            return ""
        }
    }

    const sellSelectionClass = () => {
        if (side === "SELL") {
            return "active"
        } else {
            return ""
        }
    }

    return (
        <Container fluid>
            <h1>{ market }</h1>

            <Row>
                <Col md="2">
                    <TradeForm />
                </Col>
                <Col md="2">
                    <Orderbook />
                </Col>
                <Col>
                    Chart
                </Col>
                <Col md="2">
                    <TradeHistory />
                </Col>
            </Row>

        </Container>
    )
}