import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    asks: [],
    bids: [],
}

const orderbookSlice = createSlice({
    name: 'orderbook',
    initialState,
    reducers: {
        snapshot: (state, {payload}) => {
            // `payload` structure:
            // {
            //      type: "snapshot",
            //      asks: [[price, size], ...],
            //      bids: [[price, size], ...],
            //      timestamp: timestamp,
            // }
            state.asks = payload.asks.map(x => ({
                price: x[0],
                size: x[1],
            }))
            state.bids = payload.bids.map(x => ({
                price: x[0],
                size: x[1],
            }))
        },

        l2Update: (state, {payload}) => {
            // `payload` structure:
            // {
            //      type: "update",
            //      asks: [[price, size], ...],
            //      bids: [[price, size], ...],
            // }
            for (const update of payload.asks) {
                const level = state.asks.find(x => x.price === update[0])
                if (level) {
                    if (update[1] === 0) {
                        state.asks = state.asks.filter(x => x[0] !== level.price)
                    } else {
                        level.size = update[1]
                    }                    
                } else {
                    state.asks.push({price: update[0], size: update[1]}) 
                    // TODO: more efficient inserting into correct position to maintain sort order
                }
            }

            for (const update of payload.bids) {
                const level = state.bids.find(x => x.price === update[0])
                if (level) {
                    if (update[1] === 0) {
                        state.asks = state.asks.filter(x => x[0] !== level.price)
                    } else {
                        level.bids = update[1]
                    } 
                } else {
                    state.bids.push({price: update[0], size: update[1]}) 
                    // TODO: more efficient inserting into correct position to maintain sort order
                }
            }

            state.asks.sort((a,b) => (a.price > b.price) ? 1 : -1)
            state.bids.sort((a,b) => (a.price > b.price) ? 1 : -1)
        }
    }
})

const { reducer, actions } = orderbookSlice

export const { l2Update, snapshot } = actions
export default reducer